import React from "react";
import FaqsContainer from "./FaqsContainer";
import RentalsImage from "../../../src/assets/images/faq-rentals.webp";
import { Link } from "gatsby";
import { rentals } from "./data";
import FaqCard from "./FaqCard";

export default function FaqRentals() {
  return (
    <FaqsContainer>
      <div className="mt-10">
        <div className="px-2">
          <Link to="/faqs">
            <span className="flex items-center space-x-3">
              <span>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7917 7H3.5M3.5 7L7 3.5M3.5 7L7 10.5"
                    stroke="#5C5757"
                    strokeWidth="0.403715"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="text-[#5C5757] text-xs lg:text-sm">Back</span>
            </span>
          </Link>
        </div>

        <div
          style={{
            backgroundImage: `url(${RentalsImage})`,
            backgroundPosition: "center",
          }}
          className="h-[150px] md:h-[250px] lg:h-[500px] xl:h-[520px] bg-cover mt-7 bg-no-repeat rounded-[3px] flex items-center justify-center"
        >
          <h2 className="text-white font-bold text-2xl sm:text-3xl md:text-4xl lg:text-6xl xl:text-8xl">
            Rentals
          </h2>
        </div>
      </div>

      <div className="flex flex-col space-y-4 mt-20 max-w-[1100px] mx-auto">
        {
          rentals.map((item, index) => {
            return (
              <FaqCard
                key={index}
                question={item.question}
                answer={item.answer}
              />
            );
          }
          )
        }
      </div>
    </FaqsContainer>
  );
}
