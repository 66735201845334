import React from "react";
import Layout from "../../containers/Layout";
import { Contact } from "../../components";
import FaqRentals from "../../components/FaqsComponents/FaqRentals";

export default function General() {
  return (
    <Layout title={"FAQs"}>
      <FaqRentals />
      <Contact />
    </Layout>
  );
}
